import React from "react"
import { Box, Image, Flex, Text, useBreakpointValue } from "@chakra-ui/react"

const bgImg = `/images/BG.png`
const bgMobileImg = `/images/mobile/BG2.png`
const thankImg = `/images/logo.png`
const leftImg = `/images/talent.png`
const greetingImg = `/images/GREETING.png`

const actionBg = `/images/button_diamond.png`
const action = `Nhấn nút để bắt đầu`

// markup
export const GetStartComponent = ({ onClickNext }: any) => {
  const bg = useBreakpointValue({ base: bgMobileImg, md: bgImg })
  const breakpoint = useBreakpointValue({ base: `base`, md: `md` })

  let body = <></>
  if (breakpoint === `base`) {
    body = (
      <Flex
        direction={{ base: `column`, md: `row` }}
        justify="center"
        align="center"
        height="100vh"
        pos="relative"
        maxW="1180px"
        margin="auto"
        zIndex={3}
        px={{ base: 8 }}
      >
        <Box flex={4} />
        <Image src={thankImg} alt="Logo" height={{ base: `80px`, md: `96px` }} />
        <Box flex={1} />
        <Image
          objectFit="contain"
          zIndex={2}
          src={greetingImg}
          alt=""
          width={{ base: `100%`, md: `100%` }}
          maxW="360px"
        />
        <Box flex={1} />

        <Flex direction="column" justify="center" align="center">
          <Image
            cursor="pointer"
            width="auto"
            height={{ base: `80px`, md: `130px` }}
            src={actionBg}
            alt="thank-you"
            onClick={(): void => onClickNext()}
          />
          <Text color="#4880a3" fontSize={{ base: `md`, md: `xl` }}>
            {action}
          </Text>
        </Flex>
        <Box flex={1} />

        <Image
          alignSelf={{ base: `center`, md: `flex-end` }}
          zIndex={2}
          src={leftImg}
          alt=""
          marginRight={{ base: `50%`, md: `unset` }}
          height={{ base: `33%`, md: `50%` }}
          bottom={{ base: `calc(env(safe-area-inset-bottom))`, md: `unset` }}
        />
      </Flex>
    )
  }

  if (breakpoint === `md`) {
    body = (
      <Flex
        direction={{ base: `row` }}
        justify="space-evenly"
        align="center"
        height="100vh"
        pos="relative"
        maxW="1180px"
        margin="auto"
        px={8}
        zIndex={3}
      >
        <Image top={8} right={8} pos="absolute" src={thankImg} alt="Logo" w="auto" height={{ base: `96px` }} />

        <Image
          alignSelf={{ base: `flex-end` }}
          zIndex={2}
          src={leftImg}
          alt=""
          marginRight={{ base: `unset` }}
          width={{ base: `45%` }}
          maxW={{ base: `520px` }}
        />

        <Flex marginLeft="-5%" direction="column" justify="center" align="center">
          <Image objectFit="contain" zIndex={2} src={greetingImg} alt="" width={{ base: `100%` }} maxW="560px" />
          <Box h={2} />
          <Image
            cursor="pointer"
            width="auto"
            height={{ base: `130px` }}
            src={actionBg}
            alt="thank-you"
            onClick={(): void => onClickNext()}
          />
          <Text color="#4880a3" fontSize={{ base: `xl` }}>
            {action}
          </Text>
          <Box h={20} />
        </Flex>
        <Box w={2} />
      </Flex>
    )
  }

  return (
    <Box bg="#061f35" color="#acdfff" pos="relative" minH="100vh">
      <Image
        zIndex={1}
        pos="absolute"
        right={0}
        top={0}
        bottom={0}
        left={0}
        src={bg}
        alt=""
        height={{ base: `100%` }}
        width={{ base: `100%` }}
      />
      {body}
    </Box>
  )
}
