import { Box, Flex, Image, Input, Text, useBreakpointValue } from "@chakra-ui/react"
import React from "react"

const bgImg = `/images/BG.png`
const bgMobileImg = `/images/mobile/BG2.png`
const confettiImg = `/images/confetti.png`
const confettiMobileImg = `/images/confetti-mobile.png`
const thankImg = `/images/logo.png`
const leftImg = `/images/curves-2.png`

const titleImg = `/images/thank-for-support-with-info/title.png`
const titleMobileImg = `/images/thank-for-support-with-info/titleMobile.png`
const subtitleImg = `/images/thank-for-support-with-info/subtitle.png`
const subtitleMobileImg = `/images/thank-for-support-with-info/subtitleMobile.png`
const infoImg = `/images/thank-for-support-with-info/info.png`
const infoMobileImg = `/images/thank-for-support-with-info/infoMobile.png`

const placeholderName = `Họ và Tên quý khách`
const placeholderPhone = `Số điện thoại liên hệ`

const actionBg = `/images/button_diamond.png`
const action = `Nhấn nút để tiếp tục`

// markup
export const ThankForSupportWithInfoComponent = ({ onClickNext, setName, setPhone }: any) => {
  const bg = useBreakpointValue({ base: bgMobileImg, md: bgImg })
  const bgConfetti = useBreakpointValue({ base: confettiImg, md: confettiMobileImg })
  const breakpoint = useBreakpointValue({ base: `base`, md: `md` })

  let body = <></>
  if (breakpoint === `base`) {
    body = (
      <Flex
        direction={{ base: `column` }}
        justify="center"
        align="center"
        height="100vh"
        pos="relative"
        maxW="1180px"
        margin="auto"
        zIndex={3}
        px={{ base: 8 }}
      >
        <Box flex={1} />

        <Image
          objectFit="contain"
          zIndex={2}
          src={titleMobileImg}
          alt=""
          width={{ base: `100%`, md: `100%` }}
          maxW="360px"
        />
        <Box h={2} />
        <Image
          objectFit="contain"
          zIndex={2}
          src={subtitleMobileImg}
          alt=""
          width={{ base: `100%`, md: `100%` }}
          maxW="360px"
        />
        <Box h={4} />
        <Image
          objectFit="contain"
          zIndex={2}
          src={infoMobileImg}
          alt=""
          width={{ base: `100%`, md: `100%` }}
          maxW="360px"
        />
        <Box mt={4} />

        <Box
          boxShadow="0px 2px 12px 0px #000"
          maxW="360px"
          w="100%"
          className="gradient-input"
          borderRadius={{ base: `12px`, md: `50px` }}
        >
          <Input
            onChange={(e) => {
              setName(e.target.value)
            }}
            fontSize="xl"
            color="#fff"
            type="text"
            placeholder={placeholderName}
            textAlign="center"
          />
          <span></span>
        </Box>
        <Box mt={6} />
        <Box
          boxShadow="0px 2px 12px 0px #000"
          maxW="360px"
          w="100%"
          className="gradient-input"
          borderRadius={{ base: `12px`, md: `50px` }}
        >
          <Input
            onChange={(e) => {
              console.log(`e.target.value::`, e.target.value)
              setPhone(e?.target?.value ?? ``)
            }}
            pattern="[0-9.]+"
            fontSize="xl"
            color="#fff"
            type="tel"
            placeholder={placeholderPhone}
            textAlign="center"
          />
          <span></span>
        </Box>
        <Box h={2} />

        <Image
          cursor="pointer"
          width="auto"
          height={{ base: `80px` }}
          src={actionBg}
          alt="thank-you"
          onClick={() => onClickNext()}
        />
        <Text mt="-10px" color="#4880a3" fontSize="md">
          {action}
        </Text>
        <Box flex={1} />
      </Flex>
    )
  }

  if (breakpoint === `md`) {
    body = (
      <Flex
        direction={{ base: `row` }}
        justify="start"
        align="center"
        height="100vh"
        pos="relative"
        maxW="1180px"
        margin="auto"
        zIndex={3}
      >
        <Image top={8} right={8} pos="absolute" src={thankImg} alt="Logo" w="auto" height={{ base: `96px` }} />
        <Image
          alignSelf={{ base: `flex-end` }}
          zIndex={2}
          src={leftImg}
          alt=""
          marginRight={{ base: `unset` }}
          width={{ base: `45%` }}
          maxW={{ base: `520px` }}
        />

        <Flex marginLeft="-5%" direction="column" justify="center" align="center">
          <Box h={8} />

          <Image objectFit="contain" zIndex={2} src={titleImg} alt="" width={{ base: `100%` }} maxW="560px" />
          <Box h={4} />
          <Image objectFit="contain" zIndex={2} src={subtitleImg} alt="" width={{ base: `100%` }} maxW="560px" />
          <Box h={8} />
          <Image objectFit="contain" zIndex={2} src={infoImg} alt="" width={{ base: `100%` }} maxW="560px" />
          <Box h={6} />
          <Box
            boxShadow="0px 2px 12px 0px #000"
            maxW="360px"
            w="100%"
            className="gradient-input"
            borderRadius={{ base: `12px`, md: `50px` }}
          >
            <Input
              onChange={(e) => {
                setName(e.target.value)
              }}
              fontSize="xl"
              color="#fff"
              type="text"
              placeholder={placeholderName}
              textAlign="center"
            />
            <span></span>
          </Box>

          <Box h={4} />
          <Box
            boxShadow="0px 2px 12px 0px #000"
            maxW="360px"
            w="100%"
            className="gradient-input"
            borderRadius={{ base: `12px`, md: `50px` }}
          >
            <Input
              onChange={(e) => {
                console.log(`e.target.value::`, e.target.value)
                setPhone(e?.target?.value ?? ``)
              }}
              fontSize="xl"
              color="#fff"
              type="text"
              placeholder={placeholderPhone}
              textAlign="center"
            />
            <span></span>
          </Box>
          <Box h={4} />
          <Image
            cursor="pointer"
            width="auto"
            height={{ base: `80px` }}
            src={actionBg}
            alt="thank-you"
            onClick={() => onClickNext()}
          />
          <Text mt="-10px" color="#4880a3" fontSize="md">
            {action}
          </Text>
          <Box h={20} />
        </Flex>
        <Box w={2} />
      </Flex>
    )
  }

  return (
    <Box bg="#061f35" color="#acdfff" pos="relative" minH="100vh">
      {body}
      <Image
        zIndex={1}
        pos="absolute"
        right={0}
        top={0}
        bottom={0}
        left={0}
        src={bg}
        alt=""
        height={{ base: `100%` }}
        width={{ base: `100%` }}
      />
      <Image
        zIndex={{ base: -1, md: 1 }}
        pos="absolute"
        right={0}
        top={0}
        bottom={0}
        fit="fill"
        left={0}
        src={bgConfetti}
        alt=""
        height={{ base: `100%` }}
        width={{ base: `100%` }}
      />
    </Box>
  )
}
