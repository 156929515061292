import "../css/lib.css"
import "../css/main.css"
import "../css/normalize.css"
import "../style.css"

import { Backdrop, CircularProgress } from "@material-ui/core"
import { useToast } from "@chakra-ui/react"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { isEmpty } from "lodash"
import * as queryString from "query-string"
import * as yup from "yup"

import { GetStartComponent } from "../components/get-start"
import { QuestionComponent, RatingComponent } from "../components/index"
import { ThankForSupportComponent } from "../components/thank-for-support"
import { ThankForSupportWithInfoComponent } from "../components/thank-for-support-with-info"
import { ThankForRasterized } from "../components/thank-for-rasterized"

import { FeedbackRepository } from "../repositories/feedback_repository"
import { useStyles } from "../style"

import "yup-phone"

const phoneSchema = yup.string().phone(`VN`).required()

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// Enum with 6 steps
enum Step {
  GetStart,
  Rating,
  Question, // 1-3 ratting
  AskInfo,
  ThankYou, // 1-3 ratting
  ThankForSupport, // 4-5 ratting
}

const IndexPage = ({ location }: any) => {
  const classes = useStyles()
  const toast = useToast()
  const { store: storeId } = queryString.parse(location.search)

  const [step, setStep] = useState<Step>(Step.GetStart)
  const [rating, setRating] = useState<number>(-1)
  const [feedback, setFeedback] = useState<{ [key: string]: string }>({})
  const [phone, setPhone] = useState<string>(``)
  const [name, setName] = useState<string>(``)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const showErrorMsg = async (msg: string): Promise<void> => {
    toast({
      title: msg || ``,
      status: `error`,
      duration: 2000,
      isClosable: false,
    })
  }

  const submitQuestion = (data: { [key: string]: string }): void => {
    setStep(Step.AskInfo)
    setFeedback(data)
  }

  const selectRating = async (value: number): Promise<void> => {
    setRating(value)
    await sleep(600)
    if (value > 3) {
      setStep(Step.AskInfo)
      return
    }
    setStep(Step.Question)
  }

  const onClickNextStep = () => {
    setStep(Step.Rating)
  }

  const onClickNextStepAskInfo = async () => {
    // if (isEmpty(phone) && isEmpty(name)) return showErrorMsg(`Cần nhập tên hoặc số điện thoại!`)
    if (!isEmpty(phone) && !(await phoneSchema.isValid(phone || ``)))
      return showErrorMsg(`Số điện thoại không đúng định dạng!`)
    setIsLoading(true)
    try {
      const saveData = await FeedbackRepository.saveCustomerFeedbackStore(
        name || ``,
        phone || ``,
        storeId?.toString() || ``,
        feedback,
        rating
      )
      if (saveData && saveData?.error?.message === `Feedback successfully`) {
        if (rating > 3) {
          setStep(Step.ThankForSupport)
          return
        }
        setStep(Step.ThankYou)
      } else {
        if (!!saveData?.error?.message) {
          showErrorMsg(saveData?.error?.message)
        } else {
          showErrorMsg(`Lỗi không xác định xin hãy thử lại!`)
        }
      }
    } catch (e) {
      showErrorMsg(`Lỗi không xác định xin hãy thử lại!`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <main className={classes.body}>
      <Helmet title="Trang Sức Cao Cấp PNJ | Cty Cổ Phần Vàng Bạc Đá Quý Phú Nhuận" defer={false} />

      {step === Step.GetStart && <GetStartComponent onClickNext={onClickNextStep} />}
      {step === Step.Rating && <RatingComponent rating={rating} selectRating={selectRating} />}
      {step === Step.Question && <QuestionComponent submitQuestion={submitQuestion} />}
      {step === Step.AskInfo && (
        <ThankForSupportWithInfoComponent setName={setName} setPhone={setPhone} onClickNext={onClickNextStepAskInfo} />
      )}
      {step === Step.ThankYou && <ThankForRasterized />}
      {step === Step.ThankForSupport && <ThankForSupportComponent />}

      {isLoading && (
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </main>
  )
}

export default IndexPage
