import React from "react"
import { Box, Image, Flex, useBreakpointValue } from "@chakra-ui/react"

const bgImg = `/images/Layer-1.png`
const bgMobileImg = `/images/mobile/BG4.png`
const thankImg = `/images/logo.png`
const titleImg = `/images/thank-for-rasterized/title.png`
const titleMobileImg = `/images/thank-for-rasterized/titleMobile.png`

// markup
export const ThankForRasterized = () => {
  const bg = useBreakpointValue({ base: bgMobileImg, md: bgImg })
  const breakpoint = useBreakpointValue({ base: `base`, md: `md` })

  let body = <></>
  if (breakpoint === `base`) {
    body = (
      <Flex
        direction={{ base: `column`, md: `row` }}
        justify="center"
        align="center"
        height="100vh"
        pos="relative"
        maxW="1180px"
        margin="auto"
        zIndex={3}
      >
        <Box flex={1} />
        <Image objectFit="contain" src={thankImg} alt="Logo" height={{ base: `96px` }} />
        <Box h={2} />
        <Image
          px={{ base: 8 }}
          objectFit="contain"
          zIndex={2}
          src={titleMobileImg}
          alt=""
          width={{ base: `100%`, md: `100%` }}
          maxW="560px"
        />
        <Box flex={1.5} />
      </Flex>
    )
  }

  if (breakpoint === `md`) {
    body = (
      <Flex
        direction={{ base: `row` }}
        justify="space-evenly"
        align="center"
        height="100vh"
        pos="relative"
        maxW="1180px"
        margin="auto"
        zIndex={3}
      >
        <Flex direction="column" justify="center" align="center">
          <Image objectFit="contain" src={thankImg} alt="Logo" height={{ base: `96px` }} />
          <Box h={8} />
          <Image objectFit="contain" zIndex={2} src={titleImg} alt="" width={{ base: `100%` }} maxW="560px" />
          <Box h={20} />
        </Flex>
      </Flex>
    )
  }

  return (
    <Box bg="#061f35" color="#acdfff" pos="relative" minH="100vh">
      {body}
      <Image
        zIndex={1}
        pos="absolute"
        right={0}
        top={0}
        bottom={0}
        left={0}
        src={bg}
        alt=""
        height={{ base: `100%` }}
        width={{ base: `100%` }}
      />
    </Box>
  )
}
