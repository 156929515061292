import React from "react"
import { Box, Image, Flex, useBreakpointValue } from "@chakra-ui/react"

const bgImg = `/images/BG.png`
const bgMobileImg = `/images/mobile/BG2.png`
const confettiImg = `/images/confetti.png`
const confettiMobileImg = `/images/confetti-mobile.png`
const thankImg = `/images/logo.png`
const leftImg = `/images/curves-2.png`
const titleImg = `/images/thank-for-support/title.png`
const titleMobileImg = `/images/thank-for-support/titleMobile.png`
const subtitleImg = `/images/thank-for-support/subtitle.png`

// markup
export const ThankForSupportComponent = () => {
  const bg = useBreakpointValue({ base: bgMobileImg, md: bgImg })
  const bgConfetti = useBreakpointValue({ base: confettiMobileImg, md: confettiImg })
  const breakpoint = useBreakpointValue({ base: `base`, md: `md` })

  let body = <></>
  if (breakpoint === `base`) {
    body = (
      <Flex
        direction={{ base: `column`, md: `row` }}
        justify="center"
        align="center"
        height="100vh"
        pos="relative"
        maxW="1180px"
        margin="auto"
        zIndex={3}
      >
        <Box flex={4} />
        <Image
          px={{ base: 8 }}
          objectFit="contain"
          zIndex={2}
          src={titleMobileImg}
          alt=""
          width={{ base: `100%`, md: `100%` }}
          maxW="560px"
        />
        <Box h={2} />
        <Image
          px={{ base: 8 }}
          objectFit="contain"
          zIndex={2}
          src={subtitleImg}
          alt=""
          width={{ base: `100%`, md: `100%` }}
          maxW="560px"
        />
        <Box flex={1} />
        <Image
          alignSelf={{ base: `flex-start` }}
          zIndex={2}
          src={leftImg}
          alt=""
          ml="-10%"
          height={{ base: `50vh` }}
          bottom={{ base: `calc(env(safe-area-inset-bottom))` }}
        />
      </Flex>
    )
  }

  if (breakpoint === `md`) {
    body = (
      <Flex
        direction={{ base: `row` }}
        justify="start"
        align="center"
        height="100vh"
        pos="relative"
        maxW="1180px"
        margin="auto"
        zIndex={3}
      >
        <Image
          alignSelf={{ base: `flex-end` }}
          zIndex={2}
          src={leftImg}
          alt=""
          marginRight={{ base: `unset` }}
          width={{ base: `45%` }}
          maxW={{ base: `520px` }}
        />

        <Flex marginLeft="-5%" direction="column" justify="center" align="center">
          <Image objectFit="contain" src={thankImg} alt="Logo" height={{ base: `96px` }} />
          <Box h={8} />

          <Image objectFit="contain" zIndex={2} src={titleImg} alt="" width={{ base: `100%` }} maxW="560px" />
          <Box h={2} />
          <Image objectFit="contain" zIndex={2} src={subtitleImg} alt="" width={{ base: `100%` }} maxW="560px" />
          <Box h={20} />
        </Flex>
        <Box w={2} />
      </Flex>
    )
  }

  return (
    <Box bg="#061f35" color="#acdfff" pos="relative" minH="100vh">
      {body}
      <Image
        zIndex={1}
        pos="absolute"
        right={0}
        top={0}
        bottom={0}
        left={0}
        src={bg}
        alt=""
        height={{ base: `100%` }}
        width={{ base: `100%` }}
      />
      <Image
        zIndex={{ base: 1, md: 5 }}
        pos="absolute"
        right={0}
        top={0}
        bottom={0}
        fit="fill"
        left={0}
        src={bgConfetti}
        alt=""
        height={{ base: `100%` }}
        width={{ base: `100%` }}
      />
    </Box>
  )
}
