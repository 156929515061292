import "whatwg-fetch"

console.log(`process.env.ENV_PATH ==>`, process.env.ENV_PATH)
console.log(`GATSBY_END_POINT ==>`, process.env.GATSBY_END_POINT)

export const validate: (soNum: string, phoneNum: string, company?: string) => Promise<any> = async (
  soNum: string,
  phoneNum: string,
  company?: string
) => {
  // http://localhost:8000/?soNum=2000635820&phoneNum=5577
  try {
    return await (
      await window
        .fetch(`${process.env.GATSBY_END_POINT}/cust-feedback/v1/validate-customer-feedback`, {
          method: `POST`,
          body: JSON.stringify({
            soNum: soNum,
            phoneNum: phoneNum,
            company: company,
          }),
        })
        .catch()
    ).json()
  } catch (error) {
    console.log(`error: `, error)
  }
}

export const save: (soNum: string, phoneNum:string , feedback: { [key: string]: string }, rating: number) => Promise<any> = async (
  soNum: string,
  phoneNum:string,
  feedbacks: { [key: string]: string },
  rating: number
) => {
  try {
    return await (
      await window
        .fetch(`${process.env.GATSBY_END_POINT}/cust-feedback/v1/save-customer-feedback`, {
          method: `POST`,
          body: JSON.stringify({
            soNum,
            phoneNum,
            rating,
            feedback: feedbacks,
          }),
        })
        .catch()
    ).json()
  } catch (error) {
    console.log(`error: `, error)
  }
}

export const saveCustomerFeedbackStore: (
  custName: string,
  custPhone: string,
  storeCode: string,
  feedback: { [key: string]: string },
  rating: number
) => Promise<any> = async (
  custName: string,
  custPhone: string,
  storeCode: string,
  feedbacks: { [key: string]: string },
  rating: number
) => {
  try {
    return await (
      await window
        .fetch(`${process.env.GATSBY_END_POINT}/cust-feedback/v1/save-customer-feedback-store`, {
          method: `POST`,
          body: JSON.stringify({
            custName: custName?.trim() || ``,
            custPhone: custPhone?.trim() || ``,
            storeCode,
            rating,
            feedback: feedbacks,
          }),
        })
        .catch()
    ).json()
  } catch (error) {
    console.log(`error: `, error)
  }
}

export const FeedbackRepository = {
  validate,
  save,
  saveCustomerFeedbackStore,
}
